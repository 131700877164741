<template>
  <div class="active-list scroll-box" @scroll="scrollBox($event)">
    <TopBar :type="2" :pageTitle="pageName" />
    <div class="line"></div>
    <ul class="list">
      <li class="active-info" v-for="subItem in list" :key="subItem.id" @click="toActiveInfo(subItem)">
        <img class="active-img" v-lazy="subItem.image" :key="subItem.image" />
        <div class="active-con">
          <p class="active-name vertical-ellipsis">{{ subItem.name }}</p>
          <p class="active-date">{{ subItem.created_at }}</p>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>

import TopBar from "@/m/common/topBar.vue";
export default {
  name: "ActiveList",
  components: {
    TopBar
  },
  data() {
    return {
      pageName: '',
      moduleId: '',
      list: [],
      num: 0,     //数据总个数
      limit: 10,  //一页显示几个
      nowPage: 1, //当前页码
      more: false,
    }
  },
  mounted() {
    this.moduleId = this.$route.params.id;
    this.pageName = this.$route.params.name;
    this.getList();
  },
  methods: {
    getList(){
      this.$axios.post(`/v1/article/list`, {
        page: this.nowPage,
        pre_page: this.limit,
        module_id: this.moduleId,
      }).then((res) => {
        this.list.push(...res.data.data);
        this.num = res.data.total;

        if(this.list.length<this.num){
          this.more = true;
        }
      });
    },
    //跳转活动文章详情
    toActiveInfo(data){
      if(data.type===0){
        this.$router.push('/activeInfo/'+data.data_id);
      }else if(data.type===1){
        location.href = data.pc_link;
      }
    },
    scrollBox(e) {
      if(e.target.scrollHeight-e.target.clientHeight == e.target.scrollTop && this.more){
        this.nowPage ++;
        this.getList();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.line{
  width: 100%;
  height: 20px;
  background-color: #F7F7F7;
}
.list{
  .active-info{
    box-sizing: border-box;
    padding: 28px;
    display: flex;
    justify-content: space-between;
    border-radius: 6px;
    overflow: hidden;
  }
  .active-img{
    width: 289px;
    height: 163px;
    border-radius: 6px;
  }
  .active-con{
    width: 366px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .active-name{
    font-size: 32px;
    color: #333;
  }
  .active-date{
    height: 36px;
    padding-left: 40px;
    background: url('~@/assets/clock-grey-ico.png') no-repeat left 5px/auto 24px;
    font-size: 14px;
    color: #999;
    line-height: 36px;
  }
}
</style>
